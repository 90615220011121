import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import CONFIGURATION from '../../app_configuration';
import { filterAndSortMessages } from '../../utils/sortAndFilter';

// Async thunks for asynchronous actions
export const renameConversationThread = createAsyncThunk(
	'chatbot/renameConversationThread',
	async ({ agentId, conversationId, conversationName }, { rejectWithValue }) => {
		try {
			const response = await API.put(
				CONFIGURATION.AWS_API_NAME,
				`/agents/agent-conversations/rename/${conversationId}?agent_id=${agentId}&conversation_name=${conversationName}`
			);
			return response;
		} catch (err) {
			return rejectWithValue('Error while trying to rename conversation. Please try again.');
		}
	}
);

export const getAgentList = createAsyncThunk(
	'chatbot/getAgentList',
	async (_, { rejectWithValue }) => {
		try {
			const response = await API.get(CONFIGURATION.AWS_API_NAME, '/agents/agents', {
				response: true
			});
			return response;
		} catch (err) {
			return rejectWithValue({ status: 500 });
		}
	}
);

export const getAgentSessionList = createAsyncThunk(
	'chatbot/getAgentSessionList',
	async (agentId, { rejectWithValue }) => {
		try {
			const response = await API.get(
				CONFIGURATION.AWS_API_NAME,
				`/agents/agent-conversations/${agentId}`,
				{
					response: true
				}
			);
			return { list: response?.data, agentId };
		} catch (err) {
			return rejectWithValue(err.response?.data?.detail);
		}
	}
);

export const createAgentSession = createAsyncThunk(
	'chatbot/createAgentSession',
	async (agentId, { rejectWithValue }) => {
		try {
			const response = await API.post(
				CONFIGURATION.AWS_API_NAME,
				`/agents/agent-conversations/${agentId}`
			);
			return { createdSession: response, agentId };
		} catch (err) {
			return rejectWithValue(err.response);
		}
	}
);

export const deactivateAgentSession = createAsyncThunk(
	'chatbot/deactivateAgentSession',
	async ({ agentId, historyId }, { rejectWithValue }) => {
		try {
			await API.post(CONFIGURATION.AWS_API_NAME, `/chat-history/${historyId}/deactivate`);
			return { historyId, agentId };
		} catch (err) {
			return rejectWithValue('Error while trying to delete the session. Please try again.');
		}
	}
);

export const updateMessageWithToken = createAsyncThunk(
	'chatbot/updateMessageWithToken',
	async ({ event, isFinal }, { getState, dispatch }) => {
		const state = getState();
		const agentId = event.agent_id;
		const conversationId = event.conversation_id;
		const messageHistory = state.chatbot?.history;
		const currentMessages = messageHistory?.[agentId]?.[conversationId]?.messages;

		if (!isFinal && !state.chatbot.isStreaming) {
			dispatch(setIsStreaming(true));
		}

		if (currentMessages?.length) {
			const targetId = isFinal ? event.updated_item?.timestamp : event.message_id;
			const messageToUpdateIndex = currentMessages.findIndex(
				message => message.timestamp === targetId
			);
			const updatedMessage = {
				...currentMessages[messageToUpdateIndex],
				payload: isFinal ? event.updated_item?.payload : JSON.stringify(event.message)
			};

			const updatedMessages = [...currentMessages];
			updatedMessages.splice(messageToUpdateIndex, 1, updatedMessage);

			dispatch(
				saveChatHistory({
					...messageHistory,
					[agentId]: {
						...messageHistory?.[agentId],
						[conversationId]: {
							...messageHistory?.[agentId]?.[conversationId],
							messages: updatedMessages
						}
					}
				})
			);

			if (
				isFinal &&
				JSON.parse(updatedMessage.payload)?.message?.elements?.find(
					e => e.element === 'text'
				)?.text?.length
			) {
				dispatch(setIsStreaming(false));
				dispatch(setBotMessageLoading(false));
				if (state.chatbot.speechModeActive[conversationId]) {
					dispatch(setActiveSpeechBubble(targetId));
				}
			}
		}
	}
);

export const updateHistory = createAsyncThunk(
	'chatbot/updateHistory',
	async (snapshot, { getState, dispatch }) => {
		const state = getState();
		const agentId = snapshot.agent_id;
		const conversationId = snapshot.conversation_id;
		const messageHistory = state.chatbot?.history;
		let newList = snapshot.messages ?? snapshot.history_snapshot.messages;

		if (messageHistory?.[agentId]?.[conversationId]?.messages?.length) {
			newList = filterAndSortMessages([...newList], agentId, conversationId, messageHistory);
		}

		dispatch(setHistoryLoading(false));
		dispatch(setBotMessageLoading(false));
		dispatch(
			saveChatHistory({
				...messageHistory,
				[agentId]: {
					...messageHistory?.[agentId],
					[conversationId]: {
						...snapshot,
						messages: newList
					}
				}
			})
		);
	}
);

// Chatbot slice
const chatbotSlice = createSlice({
	name: 'chatbot',
	initialState: {
		speechMode: null,
		activeSpeechBubble: null,
		sidebarVisible: false,
		history: {},
		isStreaming: false,
		agentList: [],
		chatSessionId: null,
		speechModeActive: {},
		botMessageLoading: false,
		historyLoading: false,
		error: null
	},
	reducers: {
		setSpeechMode: (state, action) => {
			state.speechModeActive = { ...state.speechModeActive, ...action.payload };
		},
		setActiveSpeechBubble: (state, action) => {
			state.activeSpeechBubble = action.payload;
		},
		toggleChatbotRightSidebar: (state, action) => {
			state.rightSidebarOpen = Boolean(action.payload);
			state.data = action.payload;
		},
		setChatbotConnected: (state, action) => {
			state.isConnected = action.payload;
		},
		saveChatHistory: (state, action) => {
			state.history = action.payload;
		},
		setChatSessionId: (state, action) => {
			state.sessionId = action.payload;
		},
		setIsStreaming: (state, action) => {
			state.isStreaming = action.payload;
		},
		setBotMessageLoading: (state, action) => {
			state.isLoading = action.payload;
		},
		setHistoryLoading: (state, action) => {
			state.isLoadingHistory = action.payload;
		},
		renameAgentSession: (state, action) => {
			const { agentId, conversationId, newName } = action.payload;
			console.log('renameAgentSession');
			if (state.sessionList[agentId].length) {
				state.sessionList[agentId] = state.sessionList[agentId].map(item => {
					console.log('item', item, conversationId, newName);
					return item.conversation_id === conversationId
						? {
								...item,
								conversation_name: newName,
								active_session: {
									...item.active_session,
									session_name: newName
								}
						  }
						: item;
				});
			}
		}
	},
	extraReducers: builder => {
		builder
			.addCase(renameConversationThread.pending, state => {
				state.renamingSession = true;
				state.error = null;
			})
			.addCase(renameConversationThread.fulfilled, (state, action) => {
				console.log(action.payload);
				state.renamingSession = false;
				state.sessionList = {
					...state.sessionList,
					[action.payload.agent.agent_id]: [
						...state.sessionList[action.payload.agent.agent_id].filter(
							item => item.conversation_id !== action.payload.conversation_id
						),
						action.payload
					]
				};
			})
			.addCase(renameConversationThread.rejected, (state, action) => {
				state.error = action.payload;
				state.renamingSession = false;
			})
			.addCase(getAgentList.fulfilled, (state, action) => {
				state.agents = action.payload;
			})
			.addCase(getAgentList.rejected, (state, action) => {
				state.error = action.payload;
			})
			.addCase(getAgentSessionList.pending, state => {
				state.errorSession = null;
				state.sessionList = [];
				state.loadingSessions = true;
			})
			.addCase(getAgentSessionList.fulfilled, (state, action) => {
				state.errorSession = null;
				state.loadingSessions = false;
				state.sessionList = {
					...state.sessionList,
					[action.payload.agentId]: action.payload.list
				};
			})
			.addCase(getAgentSessionList.rejected, (state, action) => {
				state.errorSession = action.payload;
				state.loadingSessions = false;
				state.sessionList = [];
			})
			.addCase(createAgentSession.pending, state => {
				state.creatingSession = true;
			})
			.addCase(createAgentSession.fulfilled, (state, action) => {
				state.sessionList[action.payload.agentId].unshift(action.payload.createdSession);
				state.creatingSession = false;
				state.createdSession = action.payload.createdSession;
			})
			.addCase(createAgentSession.rejected, state => {
				state.creatingSession = false;
				state.createdSession = null;
			})
			.addCase(deactivateAgentSession.fulfilled, (state, action) => {
				const { agentId, historyId } = action.payload;
				if (state.sessionList[agentId]) {
					state.sessionList[agentId] = state.sessionList?.[agentId].filter(
						session => session.history_id !== historyId
					);
				}
				state.deletedSession = historyId;
			})
			.addCase(deactivateAgentSession.rejected, (state, action) => {
				state.error = action.payload;
			});
	}
});

// Export actions and reducers
export const {
	setSpeechMode,
	setActiveSpeechBubble,
	toggleChatbotRightSidebar,
	setChatbotConnected,
	saveChatHistory,
	setChatSessionId,
	setIsStreaming,
	setBotMessageLoading,
	setHistoryLoading,
	renameAgentSession
} = chatbotSlice.actions;

export default chatbotSlice.reducer;
