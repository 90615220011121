import { useMemo } from 'react';
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import palette from './palette';
import shape from './shape';
import shadows, { customShadows } from './shadows';
import typography from './typography';
import componentsOverride from './overrides';
import GlobalStyles from './globalStyles';
import breakpoints from './breakpoints';
// REDUX

export default function ThemeConfig({ children }) {
	const themeMode = useSelector(state => state.common.themeMode);
	const isLight = themeMode === 'light';

	const themeOptions = useMemo(
		() => ({
			palette: isLight
				? { ...palette.light, mode: 'light' }
				: { ...palette.dark, mode: 'dark' },
			typography,
			breakpoints,
			zIndex: {
				appBar: 10,
				drawer: 11
			},
			shadows: isLight ? shadows.light : shadows.dark,
			shape,
			customShadows: isLight ? customShadows.light : customShadows.dark
		}),
		[isLight]
	);

	const theme = createTheme(themeOptions);
	theme.components = componentsOverride(theme);
	return (
		<ThemeProvider theme={theme}>
			<StyledEngineProvider injectFirst>
				<CssBaseline />
				<GlobalStyles />
				{children}
			</StyledEngineProvider>
		</ThemeProvider>
	);
}
