export function descendingComparator(a, b, orderBy) {
	const normalizedA =
		typeof a?.[orderBy] === 'string' ? a?.[orderBy]?.toLowerCase() : a?.[orderBy];
	const normalizedB =
		typeof b?.[orderBy] === 'string' ? b?.[orderBy]?.toLowerCase() : b?.[orderBy];

	if (normalizedB < normalizedA) {
		return -1;
	}
	if (normalizedB > normalizedA) {
		return 1;
	}
	return 0;
}

export function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) =>
				descendingComparator(
					{ ...a, ...a.file, ...a.source, ...a.ner_info },
					{ ...b, ...b.file, ...b.source, ...b.ner_info },
					orderBy
				)
		: (a, b) =>
				-descendingComparator(
					{ ...a, ...a.file, ...a.source, ...a.ner_info },
					{ ...b, ...b.file, ...b.source, ...b.ner_info },
					orderBy
				);
}

export function applySortFilter(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});

	return stabilizedThis.map(el => el[0]);
}

export const filterAndSortMessages = (
	upcomingMessages,
	agentId,
	conversationId,
	messageHistory
) => {
	const currentMessages = messageHistory?.[agentId]?.[conversationId]?.messages ?? [];
	return [...upcomingMessages, ...currentMessages]
		.sort((a, b) => a.index - b.index)
		.filter((v, i, x) => x.findIndex(v2 => v2.index === v.index) === i)
		.filter(message => message.index || message.index === 0);
};
