import { useEffect } from 'react';

// material
import { useMediaQuery } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setIsMobile } from '../../redux/slices/common';

// ----------------------------------------------------------------------

export default function MResponsive({ children, authenticated }) {
	const dispatch = useDispatch();
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

	useEffect(() => {
		dispatch(setIsMobile(isMobile));
	}, [isMobile, dispatch, authenticated]);
	return children;
}
