// material
import { styled } from '@mui/material/styles';
import { Container, Button, Box, Typography } from '@mui/material';
// hooks
import { Auth } from 'aws-amplify';
import { enqueueSnackbar } from 'notistack';
import { useLocation } from 'react-router';
import useAuth from '../../hooks/useAuth';
// components;
import LoginForm from './LoginForm';
import SetNewPasswordForm from './SetNewPasswordForm';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(() => ({
	maxWidth: 480,
	margin: 'auto',
	display: 'flex',
	minHeight: '100vh',
	flexDirection: 'column',
	justifyContent: 'center'
}));

// ----------------------------------------------------------------------

export default function Login() {
	const { method, code } = useAuth();
	const location = useLocation();
	const sso = new URLSearchParams(location.search).get('SSO');

	const handleSSOClick = async provider => {
		try {
			await Auth.federatedSignIn({ provider: sso ?? provider });
		} catch (error) {
			console.log('error', error);
			enqueueSnackbar('Error logging in via SSO', { variant: 'error' });
		}
	};

	return (
		<Container sx={{ mt: 0, pb: 0 }} maxWidth="sm">
			<ContentStyle>
				<img
					alt="Responsum Logo"
					style={{ width: 250, margin: 'auto', marginBottom: 50, marginTop: -50 }}
					src="/images/logos/logo-full-dark-background.png"
				/>
				{code === 'NEW_PASSWORD_REQUIRED' ? (
					<SetNewPasswordForm />
				) : method === 'SSO' || sso ? (
					<Box>
						<Typography align="center" variant="h4" gutterBottom>
							Sign In to Responsum
						</Typography>
						<Typography
							color="text.secondary"
							align="center"
							variant="body2"
							gutterBottom
							sx={{ mb: 2 }}
						>
							Click to Sign In with Single Sign-On
						</Typography>
						<Button fullWidth size="large" variant="contained" onClick={handleSSOClick}>
							Sign In
						</Button>
					</Box>
				) : (
					<LoginForm />
				)}
			</ContentStyle>
		</Container>
	);
}
