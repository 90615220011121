import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { enqueueSnackbar } from 'notistack';
import CONFIGURATION from '../../app_configuration';
import { COMMON_ERROR_MESSAGE } from '../../constants/index';

export const postCriteriaFetch = createAsyncThunk(
	'criteria/postCriteriaFetch',
	async ({ model, datasetFilters }, { rejectWithValue }) => {
		try {
			const response = await API.post(
				CONFIGURATION.AWS_CHATAPI_NAME,
				'/monitoring/criteria/fetch',
				{ body: { evaluated_model: model, dataset_filter: datasetFilters } }
			);
			console.log('post criteria fetch', response);
			return response;
		} catch (error) {
			enqueueSnackbar(COMMON_ERROR_MESSAGE, { variant: 'error' });
			return rejectWithValue(error.message);
		}
	}
);

// Evaluate Criteria
export const postCriteriaEvaluate = createAsyncThunk(
	'criteria/postCriteriaEvaluate',
	async ({ datasetFilters, datasetId, model, chatId, messages }, { rejectWithValue }) => {
		try {
			const response = await API.post(
				CONFIGURATION.AWS_CHATAPI_NAME,
				'/monitoring/criteria/evaluate',
				{
					body: {
						evaluated_model: model,
						dataset_id: datasetId,
						dataset_filter: datasetFilters,
						chat_id: chatId,
						messages
					}
				}
			);
			return response;
		} catch (error) {
			enqueueSnackbar(COMMON_ERROR_MESSAGE, { variant: 'error' });
			return rejectWithValue(error.message);
		}
	}
);

export const postCriteriaEvaluateCustom = createAsyncThunk(
	'criteria/evaluateCustom',
	async ({ model, datasetFilters, evaluationModel }, { rejectWithValue }) => {
		const evaluationModelQuery = evaluationModel
			? `?evaluation_model_id=${evaluationModel}`
			: '';

		try {
			const response = await API.post(
				CONFIGURATION.AWS_CHATAPI_NAME,
				`/monitoring/criteria/evaluate${evaluationModelQuery}`,
				{
					body: {
						evaluated_model: model,
						dataset_filter: datasetFilters
					}
				}
			);
			return response;
		} catch (err) {
			enqueueSnackbar(COMMON_ERROR_MESSAGE, { variant: 'error' });
			console.error(err);
			return rejectWithValue(err);
		}
	}
);

export const postFeedbackEvaluate = createAsyncThunk(
	'criteria/postFeedbackEvaluate',
	async ({ model, datasetFilters, evaluationModel }, { rejectWithValue }) => {
		try {
			const evaluationModelQuery = `?evaluation_model_id=${evaluationModel}`;
			const response = await API.post(
				CONFIGURATION.AWS_CHATAPI_NAME,
				`/monitoring/feedback/evaluate${
					evaluationModel === '' ? '' : evaluationModelQuery
				}`,
				{ body: { evaluated_model: model, dataset_filter: datasetFilters } }
			);
			return response;
		} catch (error) {
			enqueueSnackbar(COMMON_ERROR_MESSAGE, { variant: 'error' });
			return rejectWithValue(error.message);
		}
	}
);

export const postPairwiseCriteriaEvaluate = createAsyncThunk(
	'criteria/postPairwiseCriteriaEvaluate',
	async ({ evaluatedModel, baseModel, datasetFilters, evaluationModel }, { rejectWithValue }) => {
		try {
			const body = {
				evaluated_model: evaluatedModel,
				base_model: baseModel,
				dataset_filter: datasetFilters
			};
			const evaluationModelQuery = `?evaluation_model_id=${evaluationModel}`;

			const response = await API.post(
				CONFIGURATION.AWS_CHATAPI_NAME,
				`/monitoring/pairwise-criteria/evaluate${
					evaluationModel === '' ? '' : evaluationModelQuery
				}`,
				{ body }
			);
			return response;
		} catch (error) {
			enqueueSnackbar(COMMON_ERROR_MESSAGE, { variant: 'error' });
			return rejectWithValue(error.message);
		}
	}
);

export const postResolveFeedback = createAsyncThunk(
	'criteria/postResolveFeedback',
	async ({ model, datasetFilters, evaluationModel }, { rejectWithValue }) => {
		try {
			const evaluationModelQuery = `?evaluation_model_id=${evaluationModel}`;
			const response = await API.post(
				CONFIGURATION.AWS_CHATAPI_NAME,
				`/monitoring/system-prompt/recommend${
					evaluationModel === '' ? '' : evaluationModelQuery
				}`,
				{ body: { evaluated_model: model, dataset_filter: datasetFilters } }
			);
			return response;
		} catch (error) {
			enqueueSnackbar(COMMON_ERROR_MESSAGE, { variant: 'error' });
			return rejectWithValue(error.message);
		}
	}
);

export const getCriteria = createAsyncThunk(
	'criteria/getCriteria',
	async (_, { rejectWithValue }) => {
		try {
			const response = await API.get(CONFIGURATION.AWS_CHATAPI_NAME, '/monitoring/criteria');
			return response;
		} catch (err) {
			enqueueSnackbar(COMMON_ERROR_MESSAGE, { variant: 'error' });
			console.error(err);
			return rejectWithValue(err);
		}
	}
);

export const postCriteria = createAsyncThunk(
	'criteria/postCriteria',
	async (criterion, { rejectWithValue }) => {
		try {
			const response = await API.post(
				CONFIGURATION.AWS_CHATAPI_NAME,
				'/monitoring/criterion/create',
				{
					body: criterion
				}
			);
			return response;
		} catch (err) {
			enqueueSnackbar(COMMON_ERROR_MESSAGE, { variant: 'error' });
			console.error(err);
			return rejectWithValue(err);
		}
	}
);

export const postPairwiseCriteria = createAsyncThunk(
	'criteria/postPairwiseCriteria',
	async (criterion, { rejectWithValue }) => {
		try {
			const response = await API.post(
				CONFIGURATION.AWS_CHATAPI_NAME,
				'/monitoring/pairwise-criterion/create',
				{
					body: criterion
				}
			);
			return response;
		} catch (err) {
			enqueueSnackbar(COMMON_ERROR_MESSAGE, { variant: 'error' });
			console.error(err);
			return rejectWithValue(err);
		}
	}
);

export const deleteCriteria = createAsyncThunk(
	'criteria/delete',
	async (criterion, { rejectWithValue }) => {
		try {
			const response = await API.del(
				CONFIGURATION.AWS_CHATAPI_NAME,
				'/monitoring/criterion/delete',
				{
					body: criterion
				}
			);
			return response;
		} catch (err) {
			enqueueSnackbar(COMMON_ERROR_MESSAGE, { variant: 'error' });
			console.error(err);
			return rejectWithValue(err); // Reject to handle error in the reducer
		}
	}
);

export const deletePairwiseCriteria = createAsyncThunk(
	'pairwiseCriteria/delete',
	async (criterion, { rejectWithValue }) => {
		try {
			const response = await API.del(
				CONFIGURATION.AWS_CHATAPI_NAME,
				'/monitoring/pairwise-criterion/delete',
				{
					body: criterion
				}
			);
			return response;
		} catch (err) {
			enqueueSnackbar(COMMON_ERROR_MESSAGE, { variant: 'error' });
			console.error(err);
			return rejectWithValue(err);
		}
	}
);

export const postDeleteFinetune = createAsyncThunk(
	'finetune/delete',
	async (finetune, { rejectWithValue }) => {
		try {
			const response = await API.del(
				CONFIGURATION.AWS_CHATAPI_NAME,
				`/monitoring/finetunes/delete?finetune_job_id=${finetune.id}`
			);
			return response;
		} catch (err) {
			enqueueSnackbar(COMMON_ERROR_MESSAGE, { variant: 'error' });
			console.error(err);
			return rejectWithValue(err);
		}
	}
);

export const getPairwiseCriteria = createAsyncThunk(
	'pairwiseCriteria/fetch',
	async (_, { rejectWithValue }) => {
		try {
			const response = await API.get(
				CONFIGURATION.AWS_CHATAPI_NAME,
				'/monitoring/pairwise-criteria'
			);
			return response;
		} catch (err) {
			enqueueSnackbar(COMMON_ERROR_MESSAGE, { variant: 'error' });
			console.error(err);
			return rejectWithValue(err);
		}
	}
);

export const getCurrentSystemPrompt = createAsyncThunk(
	'systemPrompt/getCurrent',
	async (datasetFilters, { rejectWithValue }) => {
		try {
			const response = await API.post(
				CONFIGURATION.AWS_CHATAPI_NAME,
				`/monitoring/system-prompt/current`,
				{ body: datasetFilters }
			);
			return response;
		} catch (err) {
			enqueueSnackbar(COMMON_ERROR_MESSAGE, { variant: 'error' });
			console.error(err);
			return rejectWithValue(err);
		}
	}
);

export const postShortenSystemPrompt = createAsyncThunk(
	'systemPrompt/shorten',
	async ({ systemPrompt, evaluationModel }, { rejectWithValue }) => {
		const evaluationModelQuery = evaluationModel
			? `?evaluation_model_id=${evaluationModel}`
			: '';

		try {
			const response = await API.post(
				CONFIGURATION.AWS_CHATAPI_NAME,
				`/monitoring/system-prompt/shorten${evaluationModelQuery}`,
				{ body: { prompt: systemPrompt } }
			);
			return response.prompt;
		} catch (err) {
			enqueueSnackbar(COMMON_ERROR_MESSAGE, { variant: 'error' });
			console.error(err);
			return rejectWithValue(err);
		}
	}
);

export const postFinetuneModel = createAsyncThunk(
	'finetune/postModel',
	async ({ model, datasetFilters }, { rejectWithValue }) => {
		try {
			const response = await API.post(
				CONFIGURATION.AWS_CHATAPI_NAME,
				`/monitoring/finetune?model_id=${model}`,
				{ body: datasetFilters }
			);
			return response;
		} catch (err) {
			enqueueSnackbar(COMMON_ERROR_MESSAGE, { variant: 'error' });
			console.error(err);
			return rejectWithValue(err);
		}
	}
);

export const getFineTunedModels = createAsyncThunk(
	'finetune/getModels',
	async (_, { rejectWithValue }) => {
		try {
			const response = await API.get(CONFIGURATION.AWS_CHATAPI_NAME, `/monitoring/finetunes`);
			return response;
		} catch (err) {
			enqueueSnackbar(COMMON_ERROR_MESSAGE, { variant: 'error' });
			console.error(err);
			return rejectWithValue(err);
		}
	}
);

const testingSlice = createSlice({
	name: 'testing',
	initialState: {
		loading: false,
		error: null,
		postCriteriaEvaluation: null,
		postCriteriaFetchResult: [],
		postCriteriaNonEvaluatedCount: null,
		postEvaluateCustomResult: null,
		postCriteria: null,
		deleteCriteria: null,
		postDeleteFinetuneResult: null,
		postPairwiseCriteria: null,
		deletePairwiseCriteria: null,
		pairwiseCriteria: null,
		criteria: null,
		aggregatedPairwiseCriteria: null,
		postFeedbackEvaluationResult: null,
		postResolveFeedbackResult: null,
		getCurrentSystemPromptResult: null,
		postShortenSystemPromptResult: null,
		getFinetuneResult: null,
		postFinetuneResult: null,
		setTestingEvaluationChangedResult: null
	},
	reducers: {
		setTestingEvaluationChanged: (state, action) => {
			state.setTestingEvaluationChangedResult = action.payload;
		}
	},
	extraReducers: builder => {
		builder
			.addCase(postCriteriaEvaluate.pending, state => {
				state.loading = true;
				state.error = null;
				state.postCriteriaEvaluation = null;
			})
			.addCase(postCriteriaEvaluate.fulfilled, (state, action) => {
				state.loading = false;
				state.postCriteriaEvaluation = action.payload;
			})
			.addCase(postCriteriaEvaluate.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload || 'Failed to evaluate criteria';
			})
			.addCase(postCriteriaFetch.pending, state => {
				state.loading = true;
				state.error = null;
				state.postCriteriaFetchResult = null;
				state.postCriteriaNonEvaluatedCount = null;
			})
			.addCase(postCriteriaFetch.fulfilled, (state, action) => {
				state.loading = false;
				state.postCriteriaFetchResult = action.payload.logs;
				state.postCriteriaNonEvaluatedCount = action.payload.nonevaluated_count;
			})
			.addCase(postCriteriaFetch.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload || 'Failed to fetch criteria';
			})
			.addCase(postCriteriaEvaluateCustom.pending, state => {
				state.loading = true;
				state.error = null;
				state.postEvaluateCustomResult = null;
			})
			.addCase(postCriteriaEvaluateCustom.fulfilled, (state, action) => {
				state.loading = false;
				state.postEvaluateCustomResult = action.payload;
			})
			.addCase(postCriteriaEvaluateCustom.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload || 'Failed to evaluate custom criteria';
			})
			.addCase(postCriteria.pending, state => {
				state.loading = true;
				state.error = null;
				state.postCriteria = null;
			})
			.addCase(postCriteria.fulfilled, (state, action) => {
				state.loading = false;
				state.postCriteria = action.payload;
			})
			.addCase(postCriteria.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload || 'Failed to post criteria';
			})
			.addCase(deleteCriteria.pending, state => {
				state.loading = true;
				state.error = null;
				state.deleteCriteria = null;
			})
			.addCase(deleteCriteria.fulfilled, (state, action) => {
				state.loading = false;
				state.deleteCriteria = action.payload;
			})
			.addCase(deleteCriteria.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload || 'Failed to delete criteria';
			})
			.addCase(postDeleteFinetune.pending, state => {
				state.loading = true;
				state.error = null;
				state.postDeleteFinetuneResult = null;
			})
			.addCase(postDeleteFinetune.fulfilled, (state, action) => {
				state.loading = false;
				state.postDeleteFinetuneResult = action.payload;
			})
			.addCase(postDeleteFinetune.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload || 'Failed to delete finetune';
			})
			.addCase(postPairwiseCriteria.pending, state => {
				state.loading = true;
				state.error = null;
				state.postPairwiseCriteria = null;
			})
			.addCase(postPairwiseCriteria.fulfilled, (state, action) => {
				state.loading = false;
				state.postPairwiseCriteria = action.payload;
			})
			.addCase(postPairwiseCriteria.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload || 'Failed to post criteria';
			})
			.addCase(deletePairwiseCriteria.pending, state => {
				state.loading = true;
				state.error = null;
				state.deletePairwiseCriteria = null;
			})
			.addCase(deletePairwiseCriteria.fulfilled, (state, action) => {
				state.loading = false;
				state.deletePairwiseCriteria = action.payload;
			})
			.addCase(deletePairwiseCriteria.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload || 'Failed to delete criteria';
			})
			.addCase(getPairwiseCriteria.pending, state => {
				state.loading = true;
				state.error = null;
				state.pairwiseCriteria = null;
			})
			.addCase(getPairwiseCriteria.fulfilled, (state, action) => {
				state.loading = false;
				state.pairwiseCriteria = action.payload;
			})
			.addCase(getPairwiseCriteria.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload || 'Failed to fetch criteria';
			})
			.addCase(getCriteria.pending, state => {
				state.loading = true;
				state.error = null;
				state.criteria = null;
			})
			.addCase(getCriteria.fulfilled, (state, action) => {
				state.loading = false;
				state.criteria = action.payload;
			})
			.addCase(getCriteria.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload || 'Failed to fetch criteria';
			})
			.addCase(postPairwiseCriteriaEvaluate.pending, state => {
				state.loading = true;
				state.error = null;
				state.aggregatedPairwiseCriteria = null;
			})
			.addCase(postPairwiseCriteriaEvaluate.fulfilled, (state, action) => {
				state.loading = false;
				state.aggregatedPairwiseCriteria = action.payload;
			})
			.addCase(postPairwiseCriteriaEvaluate.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload || 'Failed to evaluate pairwise criteria';
			})
			.addCase(postFeedbackEvaluate.pending, state => {
				state.loading = true;
				state.error = null;
				state.postFeedbackEvaluationResult = null;
			})
			.addCase(postFeedbackEvaluate.fulfilled, (state, action) => {
				state.loading = false;
				state.postFeedbackEvaluationResult = action.payload;
			})
			.addCase(postFeedbackEvaluate.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload || 'Failed to evaluate feedback';
			})
			.addCase(postResolveFeedback.pending, state => {
				state.loading = true;
				state.error = null;
				state.postResolveFeedbackResult = null;
			})
			.addCase(postResolveFeedback.fulfilled, (state, action) => {
				state.loading = false;
				state.postResolveFeedbackResult = action.payload;
			})
			.addCase(postResolveFeedback.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload || 'Failed to resolve feedback';
			})
			.addCase(getCurrentSystemPrompt.pending, state => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getCurrentSystemPrompt.fulfilled, (state, action) => {
				state.loading = false;
				state.getCurrentSystemPromptResult = action.payload;
			})
			.addCase(getCurrentSystemPrompt.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload || 'Failed to fetch current system prompt';
			})
			.addCase(postShortenSystemPrompt.pending, state => {
				state.loading = true;
				state.error = null;
				state.postShortenSystemPromptResult = null;
			})
			.addCase(postShortenSystemPrompt.fulfilled, (state, action) => {
				state.loading = false;
				state.postShortenSystemPromptResult = action.payload;
			})
			.addCase(postShortenSystemPrompt.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload || 'Failed to shorten system prompt';
			})
			.addCase(getFineTunedModels.pending, state => {
				state.loading = true;
				state.error = null;
				state.getFinetuneResult = null;
			})
			.addCase(getFineTunedModels.fulfilled, (state, action) => {
				state.loading = false;
				state.getFinetuneResult = action.payload;
			})
			.addCase(getFineTunedModels.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload || 'Failed to fetch fine-tuned models';
			})
			.addCase(postFinetuneModel.pending, state => {
				state.loading = true;
				state.error = null;
				state.postFinetuneResult = null;
			})
			.addCase(postFinetuneModel.fulfilled, (state, action) => {
				state.loading = false;
				state.postFinetuneResult = action.payload;
			})
			.addCase(postFinetuneModel.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload || 'Failed to finetune model';
			});
	}
});

// Action creators are automatically generated for the reducers
export const { setTestingEvaluationChanged } = testingSlice.actions;

// Export the reducer
export default testingSlice.reducer;
