import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack'; // For notifications
import { API } from 'aws-amplify';
import CONFIGURATION from '../../app_configuration';
import { COMMON_ERROR_MESSAGE } from '../../constants/index';

// Fetch Conversation History
export const getConversationHistory = createAsyncThunk(
	'conversations/getConversationHistory',
	async (
		{ id, paginationStart, paginationSize, appendHistory = false, filters },
		{ rejectWithValue }
	) => {
		try {
			const response = await API.post(
				CONFIGURATION.AWS_API_NAME,
				`/chat-history-superuser/search-history-messages`,
				{
					body: {
						history_id: id,
						pagination_start: paginationStart,
						pagination_size: paginationSize,
						sort_asc: false,
						feedback_filter: filters
					}
				}
			);
			return { ...response, id, appendHistory };
		} catch (error) {
			enqueueSnackbar(COMMON_ERROR_MESSAGE, { variant: 'error' });
			return rejectWithValue(error.message);
		}
	}
);

export const getConversationList = createAsyncThunk(
	'conversation/getList',
	async (
		{
			paginationStart,
			paginationSize,
			sortBy,
			sort,
			tenants = [],
			users = [],
			agents = [],
			list_feedback = [],
			last_message_max_datetime,
			last_message_min_datetime,
			appendList = false
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await API.post(
				CONFIGURATION.AWS_API_NAME,
				`/chat-history-superuser/search-histories`,
				{
					response: true,
					body: {
						pagination_start: paginationStart,
						pagination_size: paginationSize,
						sort_by: sortBy,
						sort_asc: sort === 'asc',
						tenants_filter: tenants,
						users_filter: users,
						agents_filter: agents,
						feedback_filter: list_feedback,
						last_message_max_datetime,
						last_message_min_datetime
					}
				}
			);

			return { data: response?.data, appendList }; // Return data and appendList flag
		} catch (err) {
			enqueueSnackbar(COMMON_ERROR_MESSAGE, { variant: 'error' });
			console.error(err);
			return rejectWithValue(err);
		}
	}
);

// Fetch Agent List
export const getAgentList = createAsyncThunk(
	'agents/getAgentList',
	async (tenant, { rejectWithValue }) => {
		try {
			const response = await API.get(
				CONFIGURATION.AWS_API_NAME,
				`/agents-superuser/agent-configs?tenant_id=${tenant}`
			);
			return response;
		} catch (error) {
			enqueueSnackbar(COMMON_ERROR_MESSAGE, { variant: 'error' });
			return rejectWithValue(error.message);
		}
	}
);

// Get Tenant List
export const getTenantList = createAsyncThunk('tenants/getList', async (_, { rejectWithValue }) => {
	try {
		const response = await API.get(CONFIGURATION.AWS_API_NAME, '/users-superuser/tenants');
		return response;
	} catch (error) {
		enqueueSnackbar(COMMON_ERROR_MESSAGE, { variant: 'error' });
		console.error(error);
		return rejectWithValue(error);
	}
});

// Get User List
export const getUserList = createAsyncThunk(
	'users/getList',
	async ({ term, tenantIds }, { rejectWithValue }) => {
		try {
			const response = await API.post(
				CONFIGURATION.AWS_API_NAME,
				'/users-superuser/search-users',
				{ body: { search_term: term, tenant_ids: tenantIds } }
			);
			return response;
		} catch (error) {
			enqueueSnackbar(COMMON_ERROR_MESSAGE, { variant: 'error' });
			console.error(error);
			return rejectWithValue(error);
		}
	}
);

// Get Full Agent List
export const getFullAgentList = createAsyncThunk(
	'agents/getList',
	async ({ term, tenantIds }, { rejectWithValue }) => {
		try {
			const response = await API.post(
				CONFIGURATION.AWS_API_NAME,
				'/agents-superuser/search-agents',
				{ body: { search_term: term, tenant_ids: tenantIds } }
			);
			return response;
		} catch (error) {
			enqueueSnackbar(COMMON_ERROR_MESSAGE, { variant: 'error' });
			console.error(error);
			return rejectWithValue(error);
		}
	}
);

// Reducer Slice
const apiSlice = createSlice({
	name: 'apiData',
	initialState: {
		tenantList: [],
		userList: [],
		agentList: [],
		allAgents: [],
		conversationHistory: {},
		agent: null,
		loading: false,
		error: null
	},
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getConversationHistory.pending, state => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getConversationHistory.fulfilled, (state, action) => {
				const {
					messages,
					pagination_start,
					pagination_size,
					total_count,
					id,
					appendHistory
				} = action.payload;
				state.loading = false;

				if (appendHistory) {
					state.conversationHistory[id] = {
						messages: [
							...messages.reverse(),
							...(state.conversationHistory[id]?.messages || [])
						],
						pagination_start,
						pagination_size,
						total_count
					};
				} else {
					state.conversationHistory[id] = {
						messages: messages.reverse(),
						pagination_start,
						pagination_size,
						total_count
					};
				}
			})
			.addCase(getConversationHistory.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload || 'Failed to fetch conversation history';
			})
			.addCase(getTenantList.pending, state => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getTenantList.fulfilled, (state, action) => {
				state.loading = false;
				state.tenantList = action.payload;
			})
			.addCase(getTenantList.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(getUserList.pending, state => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getUserList.fulfilled, (state, action) => {
				state.loading = false;
				state.userList = action.payload;
			})
			.addCase(getUserList.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(getFullAgentList.pending, state => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getFullAgentList.fulfilled, (state, action) => {
				state.loading = false;
				state.allAgents = action.payload;
			})
			.addCase(getFullAgentList.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(getConversationList.pending, state => {
				state.loadingConversationList = true;
				state.error = null;
			})
			.addCase(getConversationList.fulfilled, (state, action) => {
				const { data, appendList } = action.payload;
				state.loadingConversationList = false;
				state.listCount = data.total_count;
				state.paginationSize = data.pagination_size;
				state.paginationStart = data.pagination_start;
				if (appendList) {
					state.conversationList = [...state.conversationList, ...data.histories];
				} else {
					state.conversationList = data.histories;
				}
			})
			.addCase(getConversationList.rejected, (state, action) => {
				state.loadingConversationList = false;
				state.error = action.payload || 'Failed to fetch conversation list';
			})
			.addCase(getAgentList.pending, state => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getAgentList.fulfilled, (state, action) => {
				state.loading = false;
				state.agentList = action.payload;
			})
			.addCase(getAgentList.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload || 'Failed to fetch agents';
			});
	}
});

export default apiSlice.reducer;
