/* eslint-disable import/no-cycle */
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Amplify } from 'aws-amplify';
import { BrowserRouter } from 'react-router-dom';
import App from './components/App';
import store from './reduxStore';
import amplifyConfig from './amplifyConfig';
import { useAuthProvider } from './hooks/useAuthProvider';

const AuthProvider = useAuthProvider();

Amplify.configure(amplifyConfig);

// get location and determine if localhost
const location = window.location.href;
const isLocalhost = location.includes('localhost');

function noop() {}
if (!isLocalhost) {
	console.log = noop;
	console.warn = noop;
	console.error = noop;
}

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<AuthProvider>
				<App />
			</AuthProvider>
		</BrowserRouter>
	</Provider>,
	document.getElementById('root')
);
