import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SnackbarProvider } from 'notistack';
import useSocketEvents from '../hooks/useSocketEvents';
import useAuth from '../hooks/useAuth';
import Loader from './Loader';
import OnlineStatus from './OnlineStatus';
import { MResponsive } from './@material-extend';
import ThemeConfig from '../theme';
import { setThemeSettings } from '../redux/slices/common';
import Router from '../routes';

function App() {
	const dispatch = useDispatch();
	const { isInitialized, isAuthenticated, user } = useAuth();
	const { initSocket, detachSocketListeners } = useSocketEvents();

	useEffect(() => {
		if (!isAuthenticated) return;
		initSocket();
		setThemeConfig(user);
		return () => {
			detachSocketListeners();
		};
	}, [isAuthenticated]);

	function setThemeConfig(activeUser) {
		const config = JSON.parse(localStorage.getItem('portalConfig'));
		const mode = config?.user === activeUser.email ? config?.themeMode : 'dark';
		dispatch(setThemeSettings(mode));
	}

	return (
		<ThemeConfig>
			<MResponsive authenticated={isAuthenticated}>
				<OnlineStatus />
				<SnackbarProvider maxSnack={3}>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						{isInitialized ? <Router /> : <Loader />}
					</LocalizationProvider>
				</SnackbarProvider>
			</MResponsive>
		</ThemeConfig>
	);
}

export default App;
