import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isMobile: false,
	themeMode: 'dark',
	isOnline: true
};

const commonSlice = createSlice({
	name: 'common',
	initialState,
	reducers: {
		// Synchronous actions
		setIsMobile: (state, action) => {
			state.isMobile = action.payload;
		},
		setThemeSettings: (state, action) => {
			state.themeMode = action.payload;
		},
		setIsOnline: (state, action) => {
			state.isOnline = action.payload;
		},
		setSidebarIsOpen: (state, action) => {
			state.sidebarIsOpen = action.payload;
		}
	}
});

export const {
	setIsMobile,
	setThemeSettings,
	setError,
	removeError,
	setIsOnline,
	setSidebarIsOpen
} = commonSlice.actions;

export default commonSlice.reducer;
