import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';

import styles from './styles';
import { LEFT_SIDEBAR_WIDTH } from '../../constants';

const useStyles = makeStyles(styles);
export default function Loader() {
	const sidebarOpened = useSelector(state => state.common.sidebarIsOpen);
	const classes = useStyles();
	const margin = sidebarOpened ? LEFT_SIDEBAR_WIDTH : 0;
	return (
		<Box sx={{ paddingLeft: margin }} className={classes.loadingPage}>
			<CircularProgress />
		</Box>
	);
}
