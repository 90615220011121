import { combineReducers } from 'redux';
import chatbotReducer from '../slices/chatbot';
import folderManager from '../slices/folderManager';
import agentManager from '../slices/agentManager';
import permissions from '../slices/permissions';
import docManagerReducer from '../slices/documentManager';
import commonReducer from '../slices/common';
import testingSuperadmin from '../slices/testingSuperadmin';
import agentManagerSuperadmin from '../slices/agentManagerSuperadmin';
import feedbackSuperadmin from '../slices/feedbackSuperadmin';

const appReducer = combineReducers({
	common: commonReducer,
	chatbot: chatbotReducer,
	docManager: docManagerReducer,
	folders: folderManager,
	permissions,
	agentManager,
	testingSuperadmin,
	agentManagerSuperadmin,
	feedbackSuperadmin
});

// clear all session on unauth_user action
const rootReducer = (state, action) => {
	if (action.type.toUpperCase() === 'UNAUTH_USER') {
		const { routing } = state;
		state = { routing };
	}
	return appReducer(state, action);
};

export default rootReducer;
