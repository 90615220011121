import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { enqueueSnackbar } from 'notistack';
import CONFIGURATION from '../../app_configuration';
import { COMMON_ERROR_MESSAGE } from '../../constants/index';

// Initial state
/* const initialState = {
	loading: false,
	error: null
}; */

export const postAddGuardrail = createAsyncThunk(
	'guardrail/postAdd',
	async ({ agentID, guardrailDescription }, { rejectWithValue }) => {
		try {
			const response = await API.post(
				CONFIGURATION.AWS_API_NAME,
				`/agents-superuser/guardrail`,
				{
					body: {
						agent_id: agentID,
						description: guardrailDescription
					}
				}
			);
			return response;
		} catch (err) {
			enqueueSnackbar(COMMON_ERROR_MESSAGE, { variant: 'error' });
			console.error(err);
			return rejectWithValue(err);
		}
	}
);

// Thunk to test a guardrail
export const postTestGuardrail = createAsyncThunk(
	'guardrail/postTest',
	async ({ message, modelID, guardrailDescription }, { rejectWithValue }) => {
		try {
			const response = await API.post(
				CONFIGURATION.AWS_CHATAPI_NAME,
				`/monitoring/send-message-to-guardrail`,
				{
					body: {
						message,
						model_id: modelID,
						guardrail_description: guardrailDescription
					}
				}
			);
			return response;
		} catch (err) {
			enqueueSnackbar(COMMON_ERROR_MESSAGE, { variant: 'error' });
			console.error(err);
			return rejectWithValue(err);
		}
	}
);

// Thunk to delete a guardrail
export const deleteGuardrail = createAsyncThunk(
	'guardrail/delete',
	async (guardrailID, { rejectWithValue }) => {
		try {
			const response = await API.del(
				CONFIGURATION.AWS_API_NAME,
				`/agents-superuser/guardrail/${guardrailID}`
			);
			return response;
		} catch (err) {
			enqueueSnackbar(COMMON_ERROR_MESSAGE, { variant: 'error' });
			console.error(err);
			return rejectWithValue(err);
		}
	}
);

// Get Agent Config by ID
export const getAgentConfig = createAsyncThunk(
	'agent/getConfigById',
	async (id, { rejectWithValue }) => {
		try {
			const response = await API.get(
				CONFIGURATION.AWS_API_NAME,
				`/agents-superuser/agent-configs/${id}`
			);
			return response;
		} catch (error) {
			enqueueSnackbar(COMMON_ERROR_MESSAGE, { variant: 'error' });
			console.error(error);
			return rejectWithValue(error);
		}
	}
);

// Thunk to update a guardrail
export const updateGuardrail = createAsyncThunk(
	'guardrail/update',
	async (guardrail, { rejectWithValue }) => {
		try {
			const response = await API.put(
				CONFIGURATION.AWS_API_NAME,
				`/agents-superuser/guardrail`,
				{
					body: {
						updated_guardrail: guardrail
					}
				}
			);
			return response;
		} catch (err) {
			enqueueSnackbar(COMMON_ERROR_MESSAGE, { variant: 'error' });
			console.error(err);
			return rejectWithValue(err);
		}
	}
);

// Thunk to get guardrails for an agent
export const getGuardrails = createAsyncThunk(
	'guardrail/get',
	async (agentID, { rejectWithValue }) => {
		try {
			const response = await API.get(
				CONFIGURATION.AWS_API_NAME,
				`/agents-superuser/guardrails?agent_id=${agentID}`
			);
			return response;
		} catch (err) {
			enqueueSnackbar(COMMON_ERROR_MESSAGE, { variant: 'error' });
			console.error(err);
			return rejectWithValue(err);
		}
	}
);

// Redux Slice
const agentManagerSuperadminSlice = createSlice({
	name: 'agentManagerSuperadmin',
	initialState: {
		loading: false,
		error: null,
		guardrailsResult: null,
		postAddGuardrailResult: null,
		postTestGuardrailResult: null,
		deleteGuardrailResult: null,
		putUpdateGuardrailResult: null,
		agent: null
	},
	reducers: {
		resetTestGuardrailState: state => {
			state.postTestGuardrailResult = undefined;
		},
		clearAgent: state => {
			state.agent = null;
		}
	},
	extraReducers: builder => {
		builder
			.addCase(getGuardrails.pending, state => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getGuardrails.fulfilled, (state, action) => {
				state.loading = false;
				state.guardrailsResult = action.payload;
			})
			.addCase(getGuardrails.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload || 'Failed to fetch guardrails';
			})
			.addCase(getAgentConfig.pending, state => {
				state.loading = true;
				state.error = null;
				state.agent = null;
			})
			.addCase(getAgentConfig.fulfilled, (state, action) => {
				state.loading = false;
				state.agent = action.payload;
			})
			.addCase(getAgentConfig.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(postAddGuardrail.pending, state => {
				state.loading = true;
				state.error = null;
				state.postAddGuardrailResult = null;
			})
			.addCase(postAddGuardrail.fulfilled, (state, action) => {
				state.loading = false;
				state.postAddGuardrailResult = action.payload;
			})
			.addCase(postAddGuardrail.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload || 'Failed to add guardrail';
			})
			.addCase(postTestGuardrail.pending, state => {
				state.loading = true;
				state.error = null;
				state.postTestGuardrailResult = null;
			})
			.addCase(postTestGuardrail.fulfilled, (state, action) => {
				state.loading = false;
				state.postTestGuardrailResult = action.payload;
			})
			.addCase(postTestGuardrail.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload || 'Failed to test guardrail';
			})
			.addCase(deleteGuardrail.pending, state => {
				state.loading = true;
				state.error = null;
				state.deleteGuardrailResult = null;
			})
			.addCase(deleteGuardrail.fulfilled, (state, action) => {
				state.loading = false;
				state.deleteGuardrailResult = action.payload;
			})
			.addCase(deleteGuardrail.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload || 'Failed to delete guardrail';
			})
			.addCase(updateGuardrail.pending, state => {
				state.loading = true;
				state.error = null;
				state.putUpdateGuardrailResult = null;
			})
			.addCase(updateGuardrail.fulfilled, (state, action) => {
				state.loading = false;
				state.putUpdateGuardrailResult = action.payload;
			})
			.addCase(updateGuardrail.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload || 'Failed to update guardrail';
			});
	}
});

// Action creators are automatically generated for the reducers
export const { resetTestGuardrailState, clearAgent } = agentManagerSuperadminSlice.actions;

// Export the reducer
export default agentManagerSuperadminSlice.reducer;
