import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import CONFIGURATION from '../../app_configuration';

// Fetch sources
export const getSources = createAsyncThunk('app/getSources', async (_, { rejectWithValue }) => {
	try {
		const response = await API.get(CONFIGURATION.AWS_API_NAME, '/content/sources', {
			response: true
		});
		return response.data;
	} catch (err) {
		return rejectWithValue({
			text: 'Failed to load critical data, please reload before proceeding with further actions. If the error persists, please contact our support.'
		});
	}
});

// Get Authorization URL
export const getAuthorizationLink = createAsyncThunk(
	'app/getAuthorizationLink',
	async (app, { rejectWithValue }) => {
		try {
			const response = await API.get(CONFIGURATION.AWS_API_NAME, '/creds/auth-url', {
				queryStringParameters: { app }
			});
			return response;
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

// Get App Access Token
export const getAppAccessToken = createAsyncThunk(
	'app/getAppAccessToken',
	async (_, { rejectWithValue }) => {
		try {
			const response = await API.get(CONFIGURATION.AWS_API_NAME, '/creds/onedrive-access', {
				response: true
			});
			return response;
		} catch (err) {
			return rejectWithValue(err.response ?? { status: 500 });
		}
	}
);

// Redeem App Auth Code
export const redeemAppAuthCode = createAsyncThunk(
	'app/redeemAppAuthCode',
	async ({ app, code }, { rejectWithValue }) => {
		const data = {
			body: {
				app,
				code
			},
			response: true
		};
		try {
			const response = await API.post(CONFIGURATION.AWS_API_NAME, '/creds/redeem', data);
			return response.status;
		} catch (err) {
			return rejectWithValue(err.response);
		}
	}
);

// Delete Access Token
export const deleteAccessToken = createAsyncThunk(
	'app/deleteAccessToken',
	async (app, { rejectWithValue }) => {
		try {
			const response = await API.del(CONFIGURATION.AWS_API_NAME, '/creds/access', {
				response: true,
				queryStringParameters: { app }
			});
			return response.status;
		} catch (err) {
			return rejectWithValue(err.response);
		}
	}
);

const appSlice = createSlice({
	name: 'app',
	initialState: {
		sources: [],
		authUrl: null,
		authToken: null,
		activeFileIndex: null,
		filesForUpload: [],
		uploadType: null,
		extractImages: false,
		modalType: null,
		classificationModal: {
			isOpen: false,
			files: []
		},
		error: null
	},
	reducers: {
		setFilesForUpload: (state, action) => {
			state.filesForUpload = action.payload;
			state.spreadsheetMode = Boolean(action.payload.find(file => file.type === 'text/csv'));
		},
		toggleModal: (state, action) => {
			state.modalType = action.payload;
			state.activeIndex = null;
			state.filesForUpload = [];
		},
		setActiveIndex: (state, action) => {
			state.activeIndex = action.payload;
		},
		toggleClassificationModal: (state, action) => {
			state.showClassModal = action.payload.isOpen;
			state.classModalId = action.payload.files?.[0]?.id;
			state.classModalFiles = action.payload.files;
		},
		toggleUploaderType: (state, action) => {
			state.uploadType = action.payload;
		},
		toggleExtractImages: (state, action) => {
			state.extractImages = action.payload;
		},
		clearAppAuth: state => {
			state.accessToken = null;
			state.oauthStatus = null;
			state.tokenStatus = null;
			state.authUrl = null;
			state.deletionTokenStatus = null;
		}
	},
	extraReducers: builder => {
		// Handle getSources
		builder.addCase(getSources.fulfilled, (state, action) => {
			state.sources = action.payload;
		});
		builder.addCase(getSources.rejected, (state, action) => {
			state.error = action.payload;
		});

		// Handle getAuthorizationLink
		builder.addCase(getAuthorizationLink.fulfilled, (state, action) => {
			state.authUrl = action.payload;
		});

		// Handle getAppAccessToken
		builder.addCase(getAppAccessToken.fulfilled, (state, action) => {
			state.accessToken = action.payload?.data?.access_token;
			state.tenant = action.payload?.data?.tenant;
			state.user = action.payload?.data?.email;
			state.tokenStatus = action.payload?.status;
		});

		builder.addCase(getAppAccessToken.rejected, (state, action) => {
			state.tokenStatus = action.payload.status;
		});

		// Handle redeemAppAuthCode
		builder.addCase(redeemAppAuthCode.fulfilled, (state, action) => {
			state.authToken = action.payload;
		});

		// Handle deleteAccessToken
		builder.addCase(deleteAccessToken.fulfilled, state => {
			state.authToken = null;
		});
	}
});

export const {
	setFilesForUpload,
	toggleModal,
	setActiveIndex,
	toggleClassificationModal,
	toggleUploaderType,
	toggleExtractImages,
	clearAppAuth
} = appSlice.actions;

export default appSlice.reducer;
